import { eventStreamService } from 'core-roblox-utilities';

const { eventTypes } = eventStreamService;
const PHONE_VERIFICATION = 'phoneVerification';

const events = {
  verifyPhonePrompt: {
    name: 'verifyPhonePrompt',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      btn: 'verifyPhonePrompt'
    }
  },
  verifyPhonePromptCancel: {
    name: 'verifyPhonePromptCancel',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      btn: 'verifyPhonePromptCancel'
    }
  },
  verificationCodeFocused: {
    name: 'verificationCodeFocused',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'focus',
      field: 'verificationCode'
    }
  },
  verifyPhoneContinue: {
    name: 'verifyPhoneContinue',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      btn: 'continue'
    }
  },
  verifyPhoneResend: {
    name: 'verifyPhoneResend',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      field: 'resendCode'
    }
  },
  verifyPhoneUseEmail: {
    name: 'verifyPhoneUseEmail',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'click',
      field: 'useEmail'
    }
  },
  verifyPhoneInvalidCode: {
    name: 'verifyPhoneInvalidCode',
    context: PHONE_VERIFICATION,
    type: eventTypes.formInteraction,
    params: {
      aType: 'shown',
      field: 'invalidCode'
    }
  }
};

export { events as default };
