import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useVerificationUpsellState from '../hooks/useVerificationUpsellState';
import VerificationUpsellModal from '../components/VerificationUpsellModal';
import PhoneNumberVerificationUpsellModal from '../components/PhoneNumberVerificationUpsellModal';

import {
  sendEmailVerification,
  updateEmailAddress,
  validateEmailAddress,
  sendVerificationUpsellEvent,
  getUserEmailAddress
} from '../services/verificationServices';
import {
  SET_MODAL_STATES,
  SET_USER_EMAIL_STATES,
  SET_PAGENAME_STATE,
  SET_INPUT_STATE,
  SET_ERROR_STATE,
  SET_EMAIL_SENT_STATE,
  SET_RESEND_EMAIL_STATE,
  SET_EMAIL_ADDED_STATE,
  SET_EMAIL_UPDATING_STATE,
  SET_TRIGGER_ORIGIN,
  SET_INPUT_CLEAR,
  INIT_PHONE_NUMBER_VERIFICATION,
  CLEAR_PHONE_NUMBER_MODAL
} from '../actions/actionTypes';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';
import events from '../constants/verificationUpsellEventStreamConstants';

const { MessageConfirmationEmailNotSent, MessageInvalidEmailAddress } = verificationUpsellConstants;

function VerificationUpsellModalContainer({ translate }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPhoneNumberModal, setIsPhoneNumberModal] = useState(false);
  const { verificationUpsellState, dispatch } = useVerificationUpsellState();

  useEffect(() => {
    window.addEventListener(
      'OpenPhoneNumberVerificationModal',
      event => {
        setIsPhoneNumberModal(true);
        setModalOpen(true);
        // Dispatch event to set phoneNumber
        dispatch({
          type: INIT_PHONE_NUMBER_VERIFICATION,
          phoneNumberParams: event.detail.phoneNumberParams,
          origin: event.detail.origin,
          usePhoneNumberCallback: event.detail.usePhoneNumberCallback,
          useEmailCallback: event.detail.useEmailCallback
        });
      },
      false
    );

    window.addEventListener(
      'OpenVerificationModal',
      event => {
        setModalOpen(true);
        dispatch({
          type: SET_TRIGGER_ORIGIN,
          origin: event.detail.origin,
          skipCallback: event.detail.skipCallback,
          closeCallback: event.detail.closeCallback
        });
        dispatch({
          type: SET_USER_EMAIL_STATES,
          isEmailVerified: event.detail.isEmailVerified,
          userEmail: event.detail.email
        });
        const pageName =
          event.detail.requireVerification && event.detail.email
            ? verificationUpsellConstants.Verification
            : verificationUpsellConstants.UpdateEmail;
        dispatch({ type: SET_PAGENAME_STATE, pageName });
        dispatch({
          type: SET_MODAL_STATES,
          experimentParameters: event.detail?.experimentParameters
        });
      },
      false
    );
  }, []);

  function handlePrimaryAction() {
    switch (verificationUpsellState.pageName) {
      case verificationUpsellConstants.Verification:
        sendVerificationUpsellEvent(events.clickResendConfirmationEmail, {
          origin: verificationUpsellState.origin
        });
        sendEmailVerification().then(rst => {
          if (!rst) {
            dispatch({ type: SET_ERROR_STATE, errorMsg: MessageConfirmationEmailNotSent });
            sendVerificationUpsellEvent(events.showError, {
              origin: verificationUpsellState.origin
            });
          } else {
            dispatch({ type: SET_EMAIL_SENT_STATE });
            setTimeout(() => {
              dispatch({ type: SET_RESEND_EMAIL_STATE });
            }, 15000);
          }
        });
        break;
      case verificationUpsellConstants.UpdateEmail:
        sendVerificationUpsellEvent(events.clickContinue, {
          origin: verificationUpsellState.origin
        });
        if (validateEmailAddress(verificationUpsellState.userEmailInput)) {
          sendVerificationUpsellEvent(events.clickSendConfirmationEmail, {
            origin: verificationUpsellState.origin
          });
          dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: true });
          updateEmailAddress({
            emailAddress: verificationUpsellState.userEmailInput
          }).then(rst => {
            if (rst === verificationUpsellConstants.InvalidEmailCode) {
              sendVerificationUpsellEvent(events.showError, {
                origin: verificationUpsellState.origin
              });
              dispatch({ type: SET_ERROR_STATE, errorMsg: MessageInvalidEmailAddress });
              dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: false });
            } else {
              // after updating email address, verification email is
              // automatically sent. Transition to the Verification modal.
              getUserEmailAddress().then(userEmail => {
                if (!userEmail) {
                  sendVerificationUpsellEvent(events.showError, {
                    origin: verificationUpsellState.origin
                  });
                  dispatch({ type: SET_ERROR_STATE, errorMsg: MessageConfirmationEmailNotSent });
                } else {
                  dispatch({
                    type: SET_USER_EMAIL_STATES,
                    isEmailVerified: false,
                    userEmail
                  });
                  dispatch({ type: SET_INPUT_CLEAR });
                  dispatch({
                    type: SET_PAGENAME_STATE,
                    pageName: verificationUpsellConstants.Verification
                  });
                  dispatch({ type: SET_MODAL_STATES });
                  dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: false });
                  dispatch({ type: SET_EMAIL_ADDED_STATE });
                }
              });
            }
          });
          dispatch({ type: SET_MODAL_STATES });
        } else {
          sendVerificationUpsellEvent(events.showError, {
            origin: verificationUpsellState.origin
          });
          dispatch({ type: SET_ERROR_STATE, errorMsg: MessageInvalidEmailAddress });
        }
        break;
      default:
    }
  }

  function handleSecondaryAction() {
    switch (verificationUpsellState.pageName) {
      case verificationUpsellConstants.Verification:
        sendVerificationUpsellEvent(events.clickChangeEmail, {
          origin: verificationUpsellState.origin
        });
        dispatch({ type: SET_PAGENAME_STATE, pageName: verificationUpsellConstants.UpdateEmail });
        dispatch({ type: SET_MODAL_STATES });
        break;
      case verificationUpsellConstants.UpdateEmail:
        if (verificationUpsellState.skipCallback) {
          sendVerificationUpsellEvent(
            verificationUpsellState.origin === verificationUpsellConstants.Logout
              ? events.skipLogoutAnyway
              : events.skipPrepurchaseVerification,
            {
              origin: verificationUpsellState.origin
            }
          );
          verificationUpsellState.skipCallback();
        }
        dispatch({ type: SET_INPUT_CLEAR });
        setModalOpen(false);
        break;
      default:
    }
  }

  function handleInputFocus() {
    sendVerificationUpsellEvent(events.touchEmail, {
      origin: verificationUpsellState.origin
    });
  }

  function handleInputChange(value) {
    dispatch({
      type: SET_INPUT_STATE,
      pageName: verificationUpsellConstants.UpdateEmail,
      value
    });
  }
  function handleKeyDown(value) {
    if (value === verificationUpsellConstants.Enter) {
      return handlePrimaryAction();
    }
    return false;
  }

  function handleBackAction() {
    dispatch({ type: SET_PAGENAME_STATE, pageName: verificationUpsellConstants.UpdateEmail });
    dispatch({ type: SET_MODAL_STATES });
  }

  function handleEmailModalDismiss() {
    sendVerificationUpsellEvent(events.dismissModal, {
      origin: verificationUpsellState.origin
    });
    dispatch({ type: SET_INPUT_CLEAR });
    if (verificationUpsellState.closeCallback) {
      verificationUpsellState.closeCallback(verificationUpsellState.isEmailAdded);
    }
    setModalOpen(false);
  }

  function handlePhoneModalDismiss() {
    dispatch({
      type: CLEAR_PHONE_NUMBER_MODAL
    });
    setModalOpen(false);
  }

  return (
    <div>
      {isPhoneNumberModal ? (
        <PhoneNumberVerificationUpsellModal
          show={isModalOpen}
          onHide={() => handlePhoneModalDismiss()}
          translate={translate}
        />
      ) : (
        <VerificationUpsellModal
          show={isModalOpen}
          onHide={() => handleEmailModalDismiss()}
          onPrimaryAction={() => handlePrimaryAction()}
          onSecondaryAction={() => handleSecondaryAction()}
          onInputFocus={() => handleInputFocus()}
          onInputChange={value => handleInputChange(value)}
          onKeyDown={value => handleKeyDown(value)}
          onBackAction={() => handleBackAction()}
          translate={translate}
        />
      )}
    </div>
  );
}

VerificationUpsellModalContainer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default VerificationUpsellModalContainer;
