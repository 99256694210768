const SET_USER_EMAIL_STATES = 'SET_USER_EMAIL_STATES';
const SET_MODAL_STATES = 'SET_MODAL_STATES';
const SET_PAGENAME_STATE = 'SET_PAGENAME_STATE';
const SET_INPUT_STATE = 'SET_INPUT_STATE';
const SET_ERROR_STATE = 'SET_ERROR_STATE';
const SET_EMAIL_SENT_STATE = 'SET_EMAIL_SENT_STATE';
const SET_RESEND_EMAIL_STATE = 'SET_RESEND_EMAIL_STATE';
const SET_EMAIL_UPDATING_STATE = 'SET_EMAIL_UPDATING_STATE';
const SET_TRIGGER_ORIGIN = 'SET_TRIGGER_ORIGIN';
const SET_INPUT_CLEAR = 'SET_INPUT_CLEAR';
const INIT_PHONE_NUMBER_VERIFICATION = 'INIT_PHONE_NUMBER_VERIFICATION';
const LOAD_PHONE_NUMBER_ENTER_CODE_PAGE = 'LOAD_PHONE_NUMBER_ENTER_CODE_PAGE';
const CLEAR_PHONE_NUMBER_MODAL = 'CLEAR_PHONE_NUMBER_MODAL';
const SET_PHONE_NUMBER_SEND_ERROR = 'SET_PHONE_NUMBER_SEND_ERROR';
const SET_EMAIL_ADDED_STATE = 'SET_EMAIL_ADDED_STATE';
export {
  SET_MODAL_STATES,
  SET_PAGENAME_STATE,
  SET_INPUT_STATE,
  SET_USER_EMAIL_STATES,
  SET_ERROR_STATE,
  SET_EMAIL_SENT_STATE,
  SET_RESEND_EMAIL_STATE,
  SET_EMAIL_UPDATING_STATE,
  SET_TRIGGER_ORIGIN,
  SET_INPUT_CLEAR,
  INIT_PHONE_NUMBER_VERIFICATION,
  LOAD_PHONE_NUMBER_ENTER_CODE_PAGE,
  CLEAR_PHONE_NUMBER_MODAL,
  SET_PHONE_NUMBER_SEND_ERROR,
  SET_EMAIL_ADDED_STATE
};
