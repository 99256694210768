const verificationUpsellConstants = {
  // page names
  Verification: 'Verification',
  UpdateEmail: 'UpdateEmail',
  UpdatePassword: 'UpdatePassword',

  PhoneNumberVerificationCTA: 'PhoneNumberVerificationCTA',
  PhoneNumberVerificationEnterCode: 'PhoneNumberVerificationEnterCode',
  PhoneNumberVerificationSendError: 'PhoneNumberVerificationSendError',

  VerifiedSignupOrigin: 'WebVerifiedSignup',
  CountdownFormatStart: ' (',
  CountdownFormatEnd: ')',
  CountdownLength: 20,
  // orgins
  Logout: 'logout',
  BuyRobux: 'buyRobuxPage',
  HomePage: 'homepage',
  SubscriptionPurchase: 'premiumSubscriptionPage',
  PurchaseWarning: 'purchaseWarning',
  Signup: 'Signup',

  // keys
  Enter: 'Enter',
  // generic error codes
  InvalidEmailCode: 9,
  InvalidPasswordCode: 8,
  // phon number error codes
  PhoneNumberAlreadyLinkedCode: 8,
  TooManyVerificationAttemptsCode: 2,
  // translation string keys
  ActionChangeEmail: 'Action.ChangeEmail',
  ActionResendConfirmationEmail: 'Action.ResendConfirmationEmail',
  ActionSendConfirmationEmail: 'Action.SendConfirmationEmail',
  ActionSent: 'Action.Sent',
  ActionLogoutSkip: 'Action.Logout',
  ActionGenericSkip: 'Action.GenericSkip',
  ActionContinue: 'Action.Continue',
  HomePageAddEmailTextOver13: 'Description.PleaseEnterEmail',
  HomePageAddEmailTextUnder13: 'Description.PleaseEnterParentEmail',
  DescriptionAddEmailTextOver13: 'Description.AddEmailTextOver13',
  DescriptionAddEmailTextUnder13: 'Description.AddEmailTextUnder13',
  DescriptionLogoutTextOver13: 'Description.LogoutAddEmailTextOver13',
  DescriptionLogoutTextUnder13: 'Description.LogoutAddEmailTextUnder13',
  DescriptionEnterPassword: 'Description.EnterPassword',
  DescriptionVerifyEmailBody: 'Description.VerifyEmailBody',
  DescriptionAddEmailTextStrongMessaging: 'Description.AddEmailTextStrongMessaging',
  HeadingAddEmailHomePage: 'Heading.PleaseAddEmail',
  HeadingAddEmail: 'Heading.AddEmail',
  HeadingVerifyEmail: 'Heading.VerifyEmail',
  HeadingVerifyOnLogout: 'Heading.VerifyOnLogout',
  HeadingCompleteSetupOnLogout: 'Heading.CompleteSetupOnLogout',
  LabelEmailInputPlaceholderOver13: 'Label.EmailInputPlaceholderOver13',
  LabelEmailInputPlaceholderUnder13: 'Label.EmailInputPlaceholderUnder13',
  LabelPasswordInputPlaceholder: 'Label.PasswordInputPlaceholder',
  MessageConfirmationEmailNotSent: 'Message.ConfirmationEmailNotSent',
  MessageInvalidEmailAddress: 'Message.InvalidEmailAddress',
  MessageWrongPassword: 'Message.WrongPassword',

  // translation strings for phone number verification
  ActionEdit: 'Action.Edit',
  ActionOk: 'Action.Ok',
  ActionChangeNumber: 'Action.ChangeNumber',
  ActionUseEmail: 'Action.UseEmail',
  ActionVerify: 'Action.Verify',
  ActionResendCode: 'Action.ResendCode',
  ActionCodeResent: 'Action.CodeResent',
  ActionLogoutWithRisk: 'Action.LogoutWithRisk',
  ActionFinishSetup: 'Action.FinishSetup',
  LabelEnterCode: 'Label.EnterCode',
  LabelVerifyPhoneNumber: 'Label.VerifyPhoneNumber',
  LabelVerificationCode: 'Heading.VerificationCode',
  MessageConfirmation: 'Message.Confirmation',
  MessageInvalidSmsCode: 'Message.InvalidSmsCode'
};

export { verificationUpsellConstants as default };
