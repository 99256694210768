import React, { useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { Button } from 'react-style-guide';
import PropTypes from 'prop-types';
import useVerificationUpsellState from '../hooks/useVerificationUpsellState';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';
import {
  LOAD_PHONE_NUMBER_ENTER_CODE_PAGE,
  SET_PHONE_NUMBER_SEND_ERROR
} from '../actions/actionTypes';
import {
  sendVerificationCodeToPhoneNumber,
  sendVerificationUpsellEvent,
  verifyWithCode
} from '../services/verificationServices';
import events from '../constants/phoneVerificationEventStreamConstants';

function PhoneNumberVerificationUpsellModal({ show, onHide, translate }) {
  const { verificationUpsellState, dispatch } = useVerificationUpsellState();
  const { phoneNumberParams, challenge, pageName, sendError, origin } = verificationUpsellState;
  const { phoneNumber, phonePrefix } = phoneNumberParams || '';
  const phoneNumberToShow = `\n+${phonePrefix}-${phoneNumber}`;
  const [code, setVerificationCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const {
    ActionEdit,
    ActionOk,
    ActionChangeNumber,
    ActionUseEmail,
    ActionVerify,
    ActionResendCode,
    ActionCodeResent,
    LabelEnterCode,
    LabelVerifyPhoneNumber,
    LabelVerificationCode,
    MessageConfirmation,
    MessageInvalidSmsCode,
    CountdownFormatStart,
    CountdownFormatEnd,
    PhoneNumberAlreadyLinkedCode,
    TooManyVerificationAttemptsCode
  } = verificationUpsellConstants;

  function handleModalExit() {
    setVerificationCode('');
    setHasError(false);
    onHide();
  }

  function handleEditClick() {
    sendVerificationUpsellEvent(events.verifyPhonePromptCancel, {
      origin
    });
    verificationUpsellState.usePhoneNumberCallback();
    handleModalExit();
  }
  function SendCode() {
    setIsSendingCode(true);
    sendVerificationCodeToPhoneNumber(phoneNumberParams)
      .then(challengeValue => {
        setIsSendingCode(false);
        dispatch({
          type: LOAD_PHONE_NUMBER_ENTER_CODE_PAGE,
          pageName: verificationUpsellConstants.PhoneNumberVerificationEnterCode,
          challenge: challengeValue
        });
      })
      .catch(err => {
        setIsSendingCode(false);
        const errors = err?.data?.errors;
        const errorCode = errors && errors[0] && errors[0].code;
        if (errorCode === PhoneNumberAlreadyLinkedCode) {
          dispatch({
            type: SET_PHONE_NUMBER_SEND_ERROR,
            errorKey: 'Response.PhoneNumberAlreadyLinked'
          });
        } else if (errorCode === TooManyVerificationAttemptsCode) {
          dispatch({
            type: SET_PHONE_NUMBER_SEND_ERROR,
            errorKey: 'Response.TooManyVerificationAttempts'
          });
        } else {
          dispatch({
            type: SET_PHONE_NUMBER_SEND_ERROR,
            errorKey: 'Response.ErrorTryAgain'
          });
        }
      });
  }

  function startCountdownTimer() {
    if (timer === 0) {
      const id = setInterval(() => {
        setTimer(time => {
          if (time === 1) {
            clearInterval(id);
          }
          return time - 1;
        });
      }, 1000);
    }
    setTimer(verificationUpsellConstants.CountdownLength);
  }

  function handleOkClick() {
    sendVerificationUpsellEvent(events.verifyPhonePrompt, {
      origin
    });
    SendCode();
    startCountdownTimer();
  }

  function handleChangeNumber() {
    verificationUpsellState.usePhoneNumberCallback();
    handleModalExit();
  }

  function handleVerificationCodeFocused() {
    sendVerificationUpsellEvent(events.verificationCodeFocused, {
      origin
    });
  }

  async function handleVerifyClick() {
    sendVerificationUpsellEvent(events.verifyPhoneContinue, {
      origin
    });
    setIsSendingCode(true);
    try {
      const { voucher } = await verifyWithCode({ challenge, code });
      setIsSendingCode(false);
      const event = new CustomEvent('PhoneNumberValidated', {
        detail: {
          voucher
        }
      });
      window.dispatchEvent(event);
      handleModalExit();
    } catch (e) {
      setIsSendingCode(false);
      sendVerificationUpsellEvent(events.verifyPhoneInvalidCode, {
        origin
      });
      setHasError(true);
    }
  }

  function handleResendCode() {
    sendVerificationUpsellEvent(events.verifyPhoneResend, {
      origin
    });
    if (timer === 0) {
      SendCode();
      startCountdownTimer();
    }
  }

  function handleUseEmailInstead() {
    sendVerificationUpsellEvent(events.verifyPhoneUseEmail, {
      origin
    });
    verificationUpsellState.useEmailCallback();
    handleModalExit();
  }

  function handleCodeChange(input) {
    setHasError(false);
    const cleanedInput = input.replace(/\s/g, '');
    // maximum 6 digits for verification code
    if (cleanedInput.length <= 6) {
      setVerificationCode(cleanedInput);
    }
  }

  function handleKeyDown(value) {
    if (value === verificationUpsellConstants.Enter) {
      return handleVerifyClick();
    }
    return false;
  }

  return (
    <Modal
      show={show}
      onHide={handleModalExit}
      /* eslint-enable */
      className='verification-modal dark-theme phone-verification-modal'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable='true'
      centered='true'>
      <Modal.Header>
        <div className='verification-upsell-title-container'>
          <button
            type='button'
            className='verification-upsell-title-button'
            onClick={handleModalExit}>
            <span className='close icon-close' />
          </button>
        </div>
      </Modal.Header>

      {pageName === verificationUpsellConstants.PhoneNumberVerificationCTA && (
        <div>
          <Modal.Body>
            <div className='phone-number-verification-upsell-image' />
            <div className='phone-number-verification-text-body phone-number-verification-text-title'>
              {translate(LabelVerifyPhoneNumber)}
            </div>
            <div className='rbx-divider underline-divider' />
            <p className='phone-number-verification-text-body'>
              {translate(MessageConfirmation, { phoneNumber: phoneNumberToShow })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className='buttons-section'>
              <Button
                type='button'
                className='cancel-btn'
                variant={Button.variants.secondary}
                onClick={handleEditClick}>
                {translate(ActionEdit)}
              </Button>
              <Button
                type='button'
                className='accept-btn'
                variant={Button.variants.primary}
                isDisabled={isSendingCode}
                onClick={handleOkClick}>
                {translate(ActionOk)}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      )}

      {pageName === verificationUpsellConstants.PhoneNumberVerificationSendError && (
        <div>
          <Modal.Body>
            <div className='phone-number-verification-upsell-image' />
            <div className='phone-number-verification-text-body phone-number-verification-text-title'>
              {translate(LabelVerifyPhoneNumber)}
            </div>
            <div className='rbx-divider underline-divider' />
            <p className='phone-number-verification-text-body'>{translate(sendError)}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className='verification-upsell-footer-container'>
              <button
                type='button'
                className='phone-number-narrow-button btn-cta-md'
                onClick={handleModalExit}>
                {translate(ActionOk)}
              </button>
            </div>
          </Modal.Footer>
        </div>
      )}

      {pageName === verificationUpsellConstants.PhoneNumberVerificationEnterCode && (
        <div>
          <Modal.Body>
            <div className='phone-number-verification-upsell-image' />
            <p className='phone-number-verification-text-body'>
              {translate(LabelEnterCode, { phoneNumber: phoneNumberToShow })}
            </p>
            <button
              type='button'
              className='phone-number-change-number-button phone-number-text-button'
              onClick={handleChangeNumber}>
              {translate(ActionChangeNumber)}
            </button>
            <p className='verification-code-label font-caption-header text-primary'>
              {translate(LabelVerificationCode)}
            </p>
            <input
              type='number'
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={`${
                hasError ? 'input-field-error' : ''
              } form-control input-field input-number verification-code-input`}
              onChange={event => handleCodeChange(event.target.value)}
              onKeyDown={event => handleKeyDown(event.key)}
              onFocus={handleVerificationCodeFocused}
              value={code}
            />
            {hasError && (
              <p className='sms-code-error text-error modal-error-message'>
                {translate(MessageInvalidSmsCode)}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn-cta-md btn-max-width phone-number-verify-button'
              disabled={isSendingCode}
              onClick={handleVerifyClick}>
              {translate(ActionVerify)}
            </button>
            {timer === 0 ? (
              <button
                type='button'
                className='phone-number-resent-button phone-number-text-button'
                onClick={handleResendCode}>
                {translate(ActionResendCode)}
              </button>
            ) : (
              <button type='button' className='phone-number-resent-button phone-number-text-button'>
                {translate(ActionCodeResent)}
                <span>{CountdownFormatStart + timer + CountdownFormatEnd}</span>
              </button>
            )}
            <button
              type='button'
              className='phone-number-text-button'
              onClick={handleUseEmailInstead}>
              {translate(ActionUseEmail)}
            </button>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

PhoneNumberVerificationUpsellModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default PhoneNumberVerificationUpsellModal;
