import { httpService } from 'core-utilities';
import { eventStreamService } from 'core-roblox-utilities';
import {
  getEmailUrlConfig,
  getEmailVerificationUrlConfig,
  getMetadataV2UrlConfig,
  getVerifiedSignup,
  getVerifiedSignupChallenge,
  getVerifiedSignupVoucher,
  getLogoutContactMethodModalExperimentConfig,
  verificationUpsellModalLogoutExperimentParameters
} from '../constants/urlConstants';
import events from '../constants/verificationUpsellEventStreamConstants';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';

export const sendVerificationUpsellEvent = (event, params) => {
  eventStreamService.sendEventWithTarget(event.type, event.context, {
    ...event.params,
    origin: params.origin
  });
};

export const sendEmailVerification = () => {
  const urlConfig = getEmailVerificationUrlConfig();
  return httpService.post(urlConfig).then(
    () => {
      return true;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const updateEmailAddress = formData => {
  const urlConfig = getEmailUrlConfig();
  return httpService.post(urlConfig, formData).then(
    () => {
      return true;
    },
    e => {
      return e.data.errors && e.data.errors[0].code;
    }
  );
};

export const getUserEmailStatusAndOpenModal = params => {
  const urlConfig = getEmailUrlConfig();
  return httpService.get(urlConfig).then(
    ({ data }) => {
      if (!data?.emailAddress || (params.requireVerification && !data.verified)) {
        const event = new CustomEvent('OpenVerificationModal', {
          detail: {
            isEmailVerified: data.verified,
            email: data?.emailAddress,
            skipCallback: params.skipCallback,
            origin: params.origin,
            experimentParameters: params?.experimentParameters,
            requireVerification: params.requireVerification,
            closeCallback: params.closeCallback
          }
        });
        sendVerificationUpsellEvent(events.showModal, {
          origin: params.origin
        });
        window.dispatchEvent(event);
      }
      return data;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const getMetadataV2 = async () => {
  const urlConfig = getMetadataV2UrlConfig();
  return httpService.get(urlConfig);
};

export const getLogoutContactMethodModalExperimentParameters = async () => {
  const urlConfig = getLogoutContactMethodModalExperimentConfig();
  const experimentParameters = {
    parameters: Object.values(verificationUpsellModalLogoutExperimentParameters).join(',')
  };
  return httpService.get(urlConfig, experimentParameters);
};

export const handleUserEmailUpsellAtLogout = async skipCallback => {
  const metadata = await getMetadataV2();
  if (!metadata.data?.IsEmailUpsellAtLogoutEnabled) {
    // see if ab test is turned off
    return false;
  }

  const experimentParameters = await getLogoutContactMethodModalExperimentParameters();
  const { Logout } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal({
    // pass in IXP params of the string resources needed.
    // call them: LogoutPrimaryText, LogoutBodyText, etc to point them
    // to resources. This gets you translations but also no direct code
    // change needed to optimize
    origin: Logout,
    experimentParameters: experimentParameters?.data,
    skipCallback
  });
};

export const handleUserEmailUpsellAtBuyRobux = skipCallback => {
  const { BuyRobux } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal({ origin: BuyRobux, skipCallback });
};

export const handleUserEmailUpsellOnHomePage = closeCallback => {
  const { HomePage } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal({ origin: HomePage, closeCallback });
};

export const handleUserEmailVerificationRequiredByPurchaseWarning = () => {
  const { PurchaseWarning } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal({ origin: PurchaseWarning, requireVerification: true });
};

export const handleUserEmailUpsellAtPremiumSubscription = skipCallback => {
  const { SubscriptionPurchase } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal({ origin: SubscriptionPurchase, skipCallback });
};

export const getUserEmailAddress = () => {
  const urlConfig = getEmailUrlConfig();
  return httpService.get(urlConfig).then(
    ({ data }) => {
      return data?.emailAddress;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const validateEmailAddress = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// phone number events
export const OpenPhoneNumberModalAtEntry = (
  origin,
  phoneNumberParams,
  usePhoneNumberCallback,
  useEmailCallback
) => {
  const event = new CustomEvent('OpenPhoneNumberVerificationModal', {
    detail: {
      origin,
      phoneNumberParams,
      usePhoneNumberCallback,
      useEmailCallback
    }
  });
  // TODO send events
  window.dispatchEvent(event);
  return true;
};

export const handlePhoneNumberVerificationAtSignup = (
  phoneNumberParams,
  usePhoneNumberCallback,
  useEmailCallback
) => {
  return OpenPhoneNumberModalAtEntry(
    verificationUpsellConstants.VerifiedSignupOrigin,
    phoneNumberParams,
    usePhoneNumberCallback,
    useEmailCallback
  );
};

export const sendVerificationCodeToPhoneNumber = ({ phoneNumber, phonePrefix, countryCode }) => {
  const urlConfig = getVerifiedSignupChallenge();
  const formData = {
    deliveryMethod: 0,
    deliveryTarget: `${phonePrefix},${phoneNumber},${countryCode}` // TODO use formatted phoneNumber
  };
  return httpService.post(urlConfig, formData).then(({ data }) => {
    if (data?.challenge) {
      return data.challenge;
    }
    return false;
  });
};

export const verifyWithCode = formData => {
  const urlConfig = getVerifiedSignupVoucher();
  return httpService.post(urlConfig, formData).then(({ data }) => {
    if (data?.voucher) {
      return data;
    }
    return false;
  });
};

export const signUpWithVoucher = formData => {
  const urlConfig = getVerifiedSignup();
  return httpService.post(urlConfig, formData).then(
    ({ data }) => {
      if (data?.userId) {
        return data.userId;
      }
      return false;
    },
    e => {
      console.log(e);
      return e;
    }
  );
};
