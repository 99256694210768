import { EnvironmentUrls } from 'Roblox';

const { accountSettingsApi, authApi, apiGatewayUrl } = EnvironmentUrls;

const getEmailUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email`
});

const getEmailVerificationUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email/verify`
});

const getMetadataV2UrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${authApi}/v2/metadata`
});

// endpoint to send sms to phone number, get challenge string
const getVerifiedSignupChallenge = () => ({
  retryable: true,
  url: `${authApi}/v1/verified-signup/challenge`
});

// send challenge string and verification code, get voucher
const getVerifiedSignupVoucher = () => ({
  retryable: true,
  url: `${authApi}/v1/verified-signup/voucher`
});

// signup a user with voucher, username, password and birthday.
const getVerifiedSignup = () => ({
  retryable: true,
  url: `${authApi}/v1/verified-signup`
});

const getLogoutContactMethodModalExperimentConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${apiGatewayUrl}/product-experimentation-platform/v1/projects/1/layers/Website.Logout.ContactMethodModal/values`
});

const verificationUpsellModalLogoutExperimentParameters = {
  header: 'alt_title',
  body: 'alt_body',
  primaryButton: 'alt_primary_button_text',
  secondaryButton: 'alt_secondary_button_text'
};

export {
  getEmailUrlConfig,
  getEmailVerificationUrlConfig,
  getMetadataV2UrlConfig,
  getVerifiedSignupChallenge,
  getVerifiedSignupVoucher,
  getVerifiedSignup,
  getLogoutContactMethodModalExperimentConfig,
  verificationUpsellModalLogoutExperimentParameters
};
