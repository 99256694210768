import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import Roblox from 'Roblox';
import App from './App';
import { rootElementId } from './app.config';
import '../../../css/verificationUpsellModal/verificationUpsellModal.scss';
import {
  handleUserEmailUpsellAtLogout,
  handleUserEmailUpsellAtBuyRobux,
  handleUserEmailUpsellOnHomePage,
  handleUserEmailVerificationRequiredByPurchaseWarning,
  handleUserEmailUpsellAtPremiumSubscription,
  handlePhoneNumberVerificationAtSignup
} from './services/verificationServices';

// Expose service to external apps
Roblox.EmailVerificationService = {
  handleUserEmailUpsellAtLogout,
  handleUserEmailUpsellAtBuyRobux,
  handleUserEmailUpsellOnHomePage,
  handleUserEmailVerificationRequiredByPurchaseWarning,
  handleUserEmailUpsellAtPremiumSubscription
};

Roblox.PhoneNumberVerificationService = {
  handlePhoneNumberVerificationAtSignup
};

ready(() => {
  if (document.getElementById(rootElementId)) {
    render(<App />, document.getElementById(rootElementId));
  }
});
